/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.halloween = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.halloween.rpcustomermain0b_1;
delete QuestsConfig.halloween.rpcustomermain1b_1;
delete QuestsConfig.halloween.rpcustomermain1c_1;
delete QuestsConfig.halloween.rpcustomermain2b_1;
delete QuestsConfig.halloween.rpcustomermain2c_1;
delete QuestsConfig.halloween.rpcustomermain2d_1;
delete QuestsConfig.halloween.rpcustomermain3b_1;
delete QuestsConfig.halloween.rpcustomermain3c_1;
delete QuestsConfig.halloween.rpcustomermain3d_1;

QuestsConfig.halloween.rpcustomerbridge1_1.trigger.unit.code = "rpcustomermain0a";
QuestsConfig.halloween.rpcustomerbridge2_1.trigger.unit.code = "rpcustomermain1a";
QuestsConfig.halloween.rpcustomerbridge3_1.trigger.unit.code = "rpcustomermain2a";

QuestsConfig.halloween.rpcustomermain1a_0 = {
    trigger: { unit: { code: "rpcustomermain0a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain1a", stage: 0 }
    }
};

QuestsConfig.halloween.rpcustomermain2a_0 = {
    trigger: { unit: { code: "rpcustomermain1a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain2a", stage: 0 }
    }
};

QuestsConfig.halloween.rpcustomermain3a_0 = {
    trigger: { unit: { code: "rpcustomermain2a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain3a", stage: 0 }
    }
};

QuestsConfig.halloween.rpproducer3.trigger = { fog: "fog1" };