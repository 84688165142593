/**
 * Created by vladislav on 27/10/2022
 */

var CustomersWindow = CleverappsWindow.extend({
    onWindowLoaded: function (customer) {
        this.customers = Map2d.currentMap.customers.listActive();
        this.selectedId = this.customers.indexOf(customer);

        var styles = cleverapps.styles.CustomersWindow;

        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);

        this.content.setContentSize2(styles);

        var multipleTabs = ["rapunzel"].includes(cleverapps.travelBook.getCurrentPage().id);
        if (multipleTabs) {
            this.tabsInfo = this.createTabs();

            this.tabs = new Tabs(this.tabsInfo, {
                direction: cleverapps.UI.VERTICAL,
                width: styles.tabs.width,
                height: styles.tabs.height
            });
            this.tabs.setAnchorPoint(0.5, 0.5);
            this.content.addChild(this.tabs);
            this.tabs.setPositionRound(styles.tabs);

            this.tabs.onTabChanged = this.createListener(this.onTabChanged.bind(this));
        } else {
            this.createTab(this.customers[this.selectedId]);
        }

        Map2d.currentMap.customers.listActive().forEach(function (customer, index) {
            customer.onUpdateAttention = this.createListener(this.updateAttention.bind(this, customer, index));
            customer.onUpdateState();
        }, this);

        var guide = this.customers[this.selectedId].getGuideOptions();

        this._super({
            name: "CustomersWindow",
            title: this.getCustomerName(customer),
            contentPadding: customer.unit.isCloseToMapLeft() ? cleverapps.UI.DOCK_RIGHT : cleverapps.UI.DOCK_LEFT,
            foreground: bundles.windows.frames.window_foreground_png,
            help: guide && function () {
                new GuideWindow(guide);
            },
            content: this.content
        });

        if (this.tabs) {
            this.tabs.activateTab(this.selectedId);
        }
    },

    getCustomerName: function (customer) {
        return cleverapps.unitsLibrary.getUnitName({
            code: customer.unit.code,
            stage: Families[customer.unit.code].oneViewForStages ? 0 : customer.unit.stage
        });
    },

    setupChildren: function () {
        this._super();

        if (Game.currentGame) {
            Map2d.currentMap.scrollToUnit(0, this.customers[this.selectedId].unit, {
                position: cleverapps.UI.DOCK_LR
            });
        }
    },

    onShow: function () {
        this._super();
        this.highlightCustomer();
    },

    onHide: function () {
        this._super();
        this.unhighlightCustomer();
    },

    onTabChanged: function (tabId) {
        var newCustomer = this.customers[tabId], oldCustomer = this.customers[this.selectedId];
        var dockChange = oldCustomer.unit.isCloseToMapLeft() !== newCustomer.unit.isCloseToMapLeft();
        this.unhighlightCustomer();

        if (dockChange) {
            this.hideSelf();
            Map2d.currentMap.scrollToUnit(0.5, newCustomer.unit, {
                position: cleverapps.UI.DOCK_LR,
                callback: function () {
                    this.close();
                    new CustomersWindow(newCustomer);
                }.bind(this)
            });
        } else {
            this.setTitle(this.getCustomerName(newCustomer));
            if (this.selectedId !== parseInt(tabId)) {
                Map2d.currentMap.scrollToUnit(0.5, newCustomer.unit, {
                    position: cleverapps.UI.DOCK_LR,
                    callback: this.createListener(function () {
                        this.highlightCustomer();
                    }.bind(this))
                });
            }
            this.selectedId = tabId;
        }
    },

    highlightCustomer: function () {
        var unit = this.customers[this.selectedId].unit;
        var unitView = unit.onGetView();

        if (!this.highlighter && unitView) {
            this.highlighter = new UnitHighlighter(unit, { spotlight: true });
        }
    },

    unhighlightCustomer: function () {
        if (this.highlighter) {
            this.highlighter.unhighlight();
            this.highlighter = undefined;
        }
    },

    updateAttention: function (customer, index) {
        if (this.tabs) {
            this.tabs.setAttention(index, customer.isRecipeReady());
        }
    },

    createTabs: function () {
        var tabs = {};
        this.customers.forEach(function (customer, index) {
            tabs[index] = {
                icon: bundles.tabs.frames["customer_tab_" + customer.unit.code],
                generator: this.createTab.bind(this, customer),
                attention: customer.isRecipeReady()
            };
        }, this);
        return tabs;
    },

    createTab: function (customer) {
        var styles = cleverapps.styles.CustomersWindow;
        var options = {
            width: styles.width,
            height: styles.height,
            exchange: function (customer) {
                if (!customer._exchages) {
                    customer._exchages = [];
                }
                customer._exchages.push(Unit.GetKey(customer.unit) + " " + Date.now() + " window: " + this.__instanceId + " active: " + cleverapps.windows.list.length);
                if (customer._exchages.length > 2) {
                    customer._exchages.shift();
                }

                var recipe = customer.getCurrentRecipe();
                if (!recipe) {
                    cleverapps.throwAsync("customer exchange with no recipe\n" + customer._exchages.join("\n") + "\n" + Map2d.currentMap.customers._openStack.join("\n"));
                    this.close();
                    return;
                }

                var spawned = recipe.listPrize().length;
                var removed = recipe.getIngredients().reduce(function (total, ingredient) {
                    return total + ingredient.requiredAmount;
                }, 0);
                var notEnough = spawned - removed - Map2d.currentMap.countEmptySlots();
                if (notEnough > 0) {
                    cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
                } else {
                    this.customerForExchange = customer;
                }
                this.close();
            }.bind(this)
        };

        var tab = new CustomerIngredientsTab(customer, options);
        this.content.addChild(tab);
        tab.setPositionRound(this.content.width / 2, this.content.height / 2);

        return tab;
    },

    beforeCloseAnimation: function (callback) {
        Map2d.currentMap.customers.clearWantsWindow();
        this.unhighlightCustomer();

        if (!this.customerForExchange) {
            callback();
            return;
        }

        this.hideSelf();
        this.customerForExchange.exchange(callback);
    }
});

cleverapps.styles.CustomersWindow = {
    width: 600,
    height: 800,

    tabs: {
        x: { align: "left", dx: -200 },
        y: { align: "top", dy: -100 },
        height: 140
    }
};
