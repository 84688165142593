/**
 * Created by andrey on 02.08.2022
 */

var ShowOutcomeWindow = function (f) {
    var outcome = this.game.outcome;
    var boatswain = this.game.boatswain;

    var transitionVictory = this.transitionVictory = outcome === GameBase.OUTCOME_VICTORY
        && Transition.isVictoryTransitionAvailable()
        && !this.level.isBonus() && !this.level.isHard()
        && !LevelStartWindow.isAvailable(MethaHelper.getCurrentLevel())
        && cleverapps.missionManager.getRunningMissions().length === 0
        && cleverapps.stickersBook && cleverapps.stickersBook.isAvailable() && cleverapps.stickersBook.isCompleted();

    if (outcome === GameBase.OUTCOME_GAVEUP) {
        f();
    } else if (outcome === GameBase.OUTCOME_LOST && cleverapps.config.type !== "battlefield") {
        ShowLoseWindow.call(this, f);
    } else if (transitionVictory) {
        var rewards = new RewardsList(this.game.rewards, {
            event: cleverapps.EVENTS.EARN.LEVEL_REWARD
        });

        rewards.receiveRewards();
        boatswain && boatswain.setIntent(boatswain.canNext() ? Boatswain.NEXT_INTENT : Boatswain.RETURN_INTENT);
        f();
    } else {
        ShowVictoryWindow.call(this, f);
    }
};

var ShowVictoryWindow = function (f) {
    new VictoryWindow(this.game);
    cleverapps.meta.onceNoWindowsListener = f;
};

var ShowLoseWindow = function (f) {
    cleverapps.meta.compound(f, [
        function (f) {
            if (cleverapps.isRumble() && cleverapps.config.name !== "differences") {
                new BeforeLoseWindow(this.game);
                cleverapps.meta.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (this.game.hasDanger()) {
                new DangerWindow(this.game);
            } else {
                new LoseWindow(this.game);
            }
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    ]);
};