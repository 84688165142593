/**
 * Created by r4zi4l on 22.06.2022
 */

var RewardTypes = {};

// GameBase.REWARD_HARD
RewardTypes.hard = RewardTypes.rubies = {
    controls: "MenuBarGoldItem",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.reward_gold_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.gold_small_png;
    },

    handler: function (value, options) {
        if (!cleverapps.isNumber(value)) {
            cleverapps.throwAsync("RewardTypes.hard - " + value);
            value = 0;
        }
        cleverapps.user.earnHard(options.event, value, true);
        return function () {
            cleverapps.user.onChangeGold();
        };
    }
};

RewardTypes.soft = {
    controls: "MenuBarCoinsItem",
    bundle: "episode_0",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.reward_coin_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.coin_small_png;
    },

    handler: function (value, options) {
        if (!cleverapps.isNumber(value)) {
            cleverapps.throwAsync("RewardTypes.soft - " + value);
            value = 0;
        }
        cleverapps.user.setSoft(cleverapps.user.soft + value, true, options);
        return function () {
            cleverapps.user.trigger("changeSoft");
        };
    }
};

// GameBase.REWARD_EXP
RewardTypes.exp = {
    controls: "MenuBarGameLevelItem",
    flyingAnimation: cleverapps.config.type === "merge" && Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return cleverapps.skins.getSlot("reward_exp_png") || bundles.reward_icons.frames.reward_exp_png;
    },

    getSmallIcon: function () {
        return cleverapps.skins.getSlot("reward_exp_small_png") || bundles.reward_icons.frames.reward_exp_small_png;
    },

    handler: function (value) {
        var expAvailable = cleverapps.exp.isAvailable();
        if (!cleverapps.isNumber(value)) {
            cleverapps.throwAsync("RewardTypes.exp - " + value);
            value = 0;
        }
        if (expAvailable) {
            cleverapps.exp.setExp(cleverapps.exp.getExp() + value, true);
        }
        return function () {
            if (expAvailable) {
                cleverapps.exp.onChange();
            }
        };
    }
};

// GameBase.REWARD_BOOSTERS
RewardTypes.boosters = {
    icon: {},
    smallIcon: {},

    getIcon: function (value) {
        return this.icon[value.id];
    },

    getSmallIcon: function (value) {
        return this.smallIcon[value.id];
    },

    parse: function (value, options) {
        return Object.keys(value).filter(function (id) {
            return value[id];
        }).map(function (id) {
            return new Reward("boosters", { id: id, amount: value[id] }, options);
        });
    },

    handler: function (value) {
        var available = value.amount > 0;
        if (available) {
            cleverapps.boosters.add(value.id, value.amount, true);
        }
        return function () {
            if (available) {
                cleverapps.boosters.onChange();
            }
        };
    }
};

RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_CELL] = bundles.reward_icons.frames.cellbooster;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_LINE] = bundles.reward_icons.frames.linebooster;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_COLOR] = bundles.reward_icons.frames.colorbooster;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_HINT] = bundles.reward_icons.frames.hint_icon_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_WILDCARD] = bundles.reward_icons.frames.joker_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_BRUSH] = bundles.reward_icons.frames.pot;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_DISCOVER] = bundles.reward_icons.frames.hat;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_COMBO] = bundles.reward_icons.frames.heroes_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_MOVES] = bundles.reward_icons.frames.moves_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_MULTICOLOR] = bundles.reward_icons.frames.multicolor_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_MAGNET] = bundles.reward_icons.frames.magnet_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_DICE] = bundles.reward_icons.frames.dice_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_JOKERS] = bundles.reward_icons.frames.joker_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_HIGHLIGHT] = bundles.reward_icons.frames.highlight;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_EYE] = bundles.reward_icons.frames.eye;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_SHUFFLE] = bundles.reward_icons.frames.shuffle;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_MAGNIFIER] = bundles.reward_icons.frames.hint;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_UNDO] = bundles.reward_icons.frames.booster_undo_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_TILE_SHUFFLE] = bundles.reward_icons.frames.booster_tile_shuffle_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_VACUUM] = bundles.reward_icons.frames.booster_vacuum_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_REPLACE_ALL] = bundles.reward_icons.frames.booster_replace_all_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_CLEAR_PIECES] = bundles.reward_icons.frames.booster_clear_pieces_png;
RewardTypes.boosters.icon[cleverapps.Boosters.TYPE_UNDO_PIECE] = bundles.reward_icons.frames.booster_undo_piece_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_CELL] = bundles.reward_icons.frames.cellbooster_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_LINE] = bundles.reward_icons.frames.linebooster_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_COLOR] = bundles.reward_icons.frames.colorbooster_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_HINT] = bundles.reward_icons.frames.hint_icon_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_WILDCARD] = bundles.reward_icons.frames.joker_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_BRUSH] = bundles.reward_icons.frames.pot_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_DISCOVER] = bundles.reward_icons.frames.hat_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_COMBO] = bundles.reward_icons.frames.heroes_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_MOVES] = bundles.reward_icons.frames.moves_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_MULTICOLOR] = bundles.reward_icons.frames.multicolor_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_MAGNET] = bundles.reward_icons.frames.magnet_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_DICE] = bundles.reward_icons.frames.dice_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_JOKERS] = bundles.reward_icons.frames.joker_small_png;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_HIGHLIGHT] = bundles.reward_icons.frames.highlight_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_EYE] = bundles.reward_icons.frames.eye_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_MAGNIFIER] = bundles.reward_icons.frames.hint_small;
RewardTypes.boosters.smallIcon[cleverapps.Boosters.TYPE_SHUFFLE] = bundles.reward_icons.frames.shuffle_small;

RewardTypes.randomBoosters = {
    getIcon: function () {
        return bundles.reward_icons.frames.boosters_common_small_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.boosters_common_small_png;
    },

    handler: function () {
        return function () {};
    }
};

RewardTypes.moves = {
    getIcon: function () {
        return bundles.reward_icons.frames.moves_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.moves_small_png;
    },

    handler: function () {
        return function () {};
    }
};

RewardTypes.battlePass = {
    bundle: "passbuyticket_window",

    getAnimation: function (value) {
        return {
            json: bundles.passbuyticket_window.jsons[value.missionType === Mission.TYPE_SALEPASS ? "purchase_ticket_json" : "ticket_json"],
            animation: "animation"
        };
    },

    handler: function () {
        return function () {};
    }
};

RewardTypes.mission = {
    icon: {},
    smallIcon: {},

    flyingAnimation: cleverapps.config.type === "merge" && Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function (value) {
        var missionType = Mission.ParseCompoundType(value.missionType).type;
        return this.icon[missionType] || Mission.getCollectIcon(missionType);
    },

    getSmallIcon: function (value) {
        var missionType = Mission.ParseCompoundType(value.missionType).type;
        return this.smallIcon[missionType];
    },

    parse: function (value, options) {
        var mission = cleverapps.missionManager.findRunningMission(value.missionType);
        if (mission) {
            return [new Reward("mission", value, options)];
        }
        return [];
    },

    handler: function (value) {
        var mission = cleverapps.missionManager.findByType(value.missionType);
        if (mission && !mission.isRunning()) {
            mission = undefined;
        }
        if (mission) {
            if (mission.isPassMission() || mission.isTreasureSearchMission()) {
                cleverapps.missionManager.dispatchEvent(mission, value);
            } else if (mission.isSlotMachineMission()) {
                mission.logic.slotMachine.setTickets(mission.logic.slotMachine.tickets + value.amount, true);
            } else if (mission.isPinballMission()) {
                mission.logic.updateChips(value.amount);
            } else {
                mission.update(value.amount, undefined, true);
            }
        }

        return cleverapps.once(function () {
            if (mission) {
                mission.onUpdateMission(value.amount);

                if (mission.isSlotMachineMission()) {
                    mission.logic.slotMachine.trigger("updateTickets");
                }
            }
        });
    }
};

RewardTypes.mission.icon[Mission.TYPE_SOFTFEAST] = bundles.reward_icons.frames.reward_coin_png;
RewardTypes.mission.icon[Mission.TYPE_SLOT_MACHINE] = bundles.reward_icons.frames.slot_machine_ticket;

RewardTypes.kraken = {
    flyingAnimation: Reward.NO_ANIMATION,

    getIcon: function () {
        return bundles.reward_icons.frames.tentacle_png;
    },

    handler: function () {
        Game.currentGame.monstersPlanner.spawnToAllSlots();
        return function () {};
    }
};

// GameBase.REWARD_SECONDARY
RewardTypes.clover = {
    getIcon: function () {
        return Game.currentGame && Game.currentGame.secondaryMission && Game.currentGame.secondaryMission.getIcon();
    },

    getCollectRewardSound: function () {
        return Game.currentGame && Game.currentGame.secondaryMission && Game.currentGame.secondaryMission.getCollectRewardSound();
    },

    parse: function (value, options) {
        if (!Game.currentGame || !Game.currentGame.secondaryMission || !Game.currentGame.secondaryMission.isRunning()) {
            return [];
        }
        return [new Reward("clover", value, options)];
    },

    handler: function (value) {
        var mission = Game.currentGame && Game.currentGame.secondaryMission;
        return RewardTypes.mission.handler({
            missionType: mission && mission.type,
            amount: value
        });
    }
};

RewardTypes.lantern = {
    flyingAnimation: Reward.CUSTOM_COLLECT,

    parse: function (value, options) {
        if (!Game.currentGame || !Game.currentGame.level || !Lantern.GetCurrentStreak(Game.currentGame.level)) {
            return [];
        }
        return [new Reward(
            "lantern",
            value,
            Object.assign({ currentStreak: Lantern.GetCurrentStreak(Game.currentGame.level) }, options)
        )];
    },

    getCustomRewardIcon: function () {
        return bundles.lantern.jsons.lantern_reward_json;
    },

    getCustomRewardIconAnimation: function (options) {
        options = options || {};
        return {
            before: "idle_" + String(options.currentStreak - 1),
            during: "stage_" + String(options.currentStreak),
            after: "idle_" + String(options.currentStreak)
        };
    },

    getCustomRewardOpenSound: function () {
        return bundles.lantern.urls.lantern_win_effect;
    },

    getCustomRewardProgress: function () {
        return bundles.lantern.jsons.lantern_progress_json;
    },

    getCustomRewardProgressAnimation: function (options) {
        return RewardTypes.lantern.getCustomRewardIconAnimation(options);
    },

    handler: function () {
        return function () {};
    }
};

RewardTypes.cup = {
    icon: {},
    smallIcon: {},
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function (value) {
        return this.icon[value.type];
    },

    getSmallIcon: function (value) {
        return this.smallIcon[value.type];
    },

    handler: function (value) {
        var cup;

        switch (value.type) {
            case CupsConfig.TYPE_DAILY: cup = cleverapps.dailyCup; break;
            case CupsConfig.TYPE_WEEKLY: cup = cleverapps.weeklyCup; break;
            case CupsConfig.TYPE_CLAN: cup = cleverapps.clanCup; break;
        }

        if (cup) {
            cup.addAmount(value.amount);

            if (cup.innerCup) {
                cup.innerCup.addAmount(value.amount);
            }
        }

        return function () {};
    }
};

RewardTypes.joker = {
    getIcon: function () {
        return bundles.reward_icons.frames.stickers_joker_png;
    },

    handler: function () {
        return function () {};
    }
};

RewardTypes.noAds = {
    getIcon: function () {
        return bundles.reward_icons.frames.noads_png;
    },

    handler: function () {
        cleverapps.noAds.enable();
    }
};

RewardTypes.growthFund = {
    getIcon: function () {
        return bundles.reward_icons.frames.growth_fund_png;
    },

    handler: function () {
        if (cleverapps.growthFund && !cleverapps.growthFund.isBought()) {
            cleverapps.growthFund.processBought(true);
        }
    }
};

RewardTypes.pinballTickets = {
    getIcon: function () {
        return bundles.reward_icons.frames.pinball_ticket_png;
    },

    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    handler: function (value) {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PINBALL);
        mission.logic.updateTickets(value.amount || 0);

        return function () {};
    }
};

RewardTypes.pinballChips = {
    getIcon: function () {
        return bundles.reward_icons.frames.pinball_token_png;
    },

    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    handler: function (value) {
        var mission = cleverapps.missionManager.findByType(Mission.TYPE_PINBALL);
        mission.logic.updateChips(value || 0);

        return function () {};
    }
};

RewardTypes.cup.icon[CupsConfig.TYPE_CLAN] = bundles.reward_icons.frames.cup_png;
RewardTypes.cup.smallIcon[CupsConfig.TYPE_CLAN] = bundles.reward_icons.frames.cup_small_png;
