/**
 * Created by r4zi4l on 09.02.2021
 */

var MergeAdviceMineLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);

    options = options || {};
    this.monster = options.monster;
};

MergeAdviceMineLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceMineLogic.prototype.constructor = MergeAdviceMineLogic;

MergeAdviceMineLogic.prototype.findMove = function () {
    if (this.monster) {
        return this.findMonsterMove();
    }

    if (!Map2d.currentMap.workers.findFree()) {
        return;
    }

    var mineable = this.listAvailableUnits().map(function (unit) {
        return unit.findComponent(Mineable);
    }).filter(function (mineable) {
        return mineable && mineable.state === Mineable.STATES.WAIT && !Map2d.currentMap.workers.findAssigned(mineable.unit);
    }).sort(function (a, b) {
        return b.mined - a.mined;
    })[0];

    if (mineable) {
        return {
            cells: [cc.p(mineable.unit.x, mineable.unit.y)],
            unitView: ["energy"]
        };
    }
};

MergeAdviceMineLogic.prototype.findMonsterMove = function () {
    var monsters = [];

    var region = Map2d.currentMap.regions.monsters;
    for (var i = 0; i < region.positions.length; i++) {
        var monster = Map2d.currentMap.getUnit(region.positions[i].x, region.positions[i].y);
        var info = monster && monster.getActionInfo();
        if (monster && monster.getData().monster === this.monster && this.isApplicable(monster)
            && info && info.buttons.wands.wands <= Game.currentGame.wands) {
            monsters.push(monster);
        }
    }

    var target = monsters[0];
    if (target) {
        return {
            cells: [cc.p(target.x, target.y)],
            unitView: ["wandsAttack"]
        };
    }
};