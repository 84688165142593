/**
 * Created by r4zi4l on 13.01.2021
 */

var UnitsLibraryWindow = CleverappsWindow.extend({
    onWindowLoaded: function (unit) {
        this.searchUnit = unit;

        this.tabsInfo = this.createTabs();

        this._super({
            name: "UnitsLibraryWindow",
            content: this.createContent(),
            noPadding: true,
            bandButton: ["riddles", "heroes"].indexOf(cleverapps.config.ui) !== -1,
            styles: cleverapps.styles.UnitsLibraryWindow.window,
            foreground: bundles.windows.frames.window_foreground_png,
            openSound: bundles.unitslibrarywindow.urls.new_unit_window_effect
        });
    },

    createTabs: function () {
        var info = this.createTabsInfo();

        Object.keys(info).forEach(function (id) {
            if (cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id, id).length === 0) {
                delete info[id];
            }
        });

        return info;
    },

    createContent: function () {
        var styles = cleverapps.styles.UnitsLibraryWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles.width, styles.height);

        var tabs = this.tabs = new Tabs(this.tabsInfo, styles.tabs);
        tabs.setAnchorPoint(0.5, 0.5);
        tabs.setPositionRound(styles.tabs);
        content.addChild(tabs);

        var activeTab;
        var freshTab;

        for (var id in this.tabsInfo) {
            var codes = cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id, id);
            if (!activeTab && this.searchUnit && codes.indexOf(this.searchUnit.code) !== -1) {
                activeTab = id;
            }
            if (!freshTab && cleverapps.unitsLibrary.hasAnyFresh(codes)) {
                freshTab = id;
            }
        }

        tabs.activateTab(activeTab || freshTab || Object.keys(this.tabsInfo)[0], { searchUnit: this.searchUnit });

        cleverapps.eventBus.on("unitStatusChanged", this.updateTabsAttentions.bind(this), this);
        this.updateTabsAttentions();

        return content;
    },

    updateTabsAttentions: function () {
        for (var id in this.tabsInfo) {
            var codes = cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id, id);
            this.tabs.setAttention(id, cleverapps.unitsLibrary.hasAnyFresh(codes));
        }
    },

    createTab: function (Tab, id) {
        var styles = cleverapps.styles.UnitsLibraryWindow;
        var options = {
            width: styles.width - styles.padding.x * 2,
            height: styles.height - styles.padding.y * 2
        };

        var codes = cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id, id);
        var content = new Tab(codes, options);
        this.content.addChild(content);
        return content;
    },

    createTabsInfo: function () {
        if (cleverapps.travelBook.getCurrentPage().id === "collections") {
            return {
                0: {
                    icon: bundles.pet_tab_icons.frames.tab_icon_regular,
                    generator: this.createTab.bind(this, UnitsTab, 0)
                },
                1: {
                    icon: bundles.pet_tab_icons.frames.tab_icon_rare,
                    generator: this.createTab.bind(this, UnitsTab, 1)
                },
                2: {
                    icon: bundles.pet_tab_icons.frames.tab_icon_legend,
                    generator: this.createTab.bind(this, UnitsTab, 2)
                },
                4: {
                    icon: bundles.windows.frames.tab_icon_fruit,
                    generator: this.createTab.bind(this, UnitsTab, 4)
                },
                6: {
                    icon: bundles.windows.frames.tab_icon_chest,
                    generator: this.createTab.bind(this, UnitsTab, 6)
                },
                7: {
                    icon: bundles.windows.frames.tab_icon_valuable,
                    generator: this.createTab.bind(this, UnitsTab, 7)
                }
            };
        }

        return {
            0: {
                icon: bundles.windows.frames.tab_icon_heroes,
                generator: this.createTab.bind(this, Game.currentGame.isMainGame() ? HeroesTab : UnitsTab, 0)
            },
            1: {
                icon: bundles.windows.frames.tab_icon_resource,
                generator: this.createTab.bind(this, UnitsTab, 1)
            },
            2: {
                icon: bundles.windows.frames.tab_icon_fruit,
                generator: this.createTab.bind(this, UnitsTab, 2)
            },
            3: {
                icon: bundles.windows.frames.tab_icon_source,
                generator: this.createTab.bind(this, UnitsTab, 3)
            },
            4: {
                icon: bundles.windows.frames.tab_icon_chest,
                generator: this.createTab.bind(this, UnitsTab, 4)
            },
            5: {
                icon: bundles.windows.frames.tab_icon_valuable,
                generator: this.createTab.bind(this, UnitsTab, 5)
            }
        };
    },

    listBundles: function () {
        var bundles = {};
        bundles["landmarkdonor_window"] = true;

        var tabIds = Object.keys(this.createTabsInfo());

        tabIds.forEach(function (tabId) {
            var codes = cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id, tabId);

            codes.forEach(function (code) {
                var units = cleverapps.unitsLibrary.listOpenedPlusOneWithUnknown(code).filter(function (unit) {
                    return !unit.unknown || (cleverapps.config.debugMode && cleverapps.flags.keyboardAnchorsMode);
                });

                units.forEach(function (unit) {
                    var res = UnitView.UnitIconJson(unit) || UnitView.UnitIconFrame(unit);
                    if (res && res.isLazy()) {
                        bundles[res.getBundleName()] = true;
                    }
                });
            });
        });

        return Object.keys(bundles);
    }
});

cleverapps.styles.UnitsLibraryWindow = {
    width: 2000,
    height: 1100,

    padding: {
        x: 60,
        y: 25
    },

    tabs: {
        x: { align: "left", dx: -118 },
        y: { align: "top", dy: -40 },
        direction: cleverapps.UI.VERTICAL,
        height: 140
    },

    window: {
        Background: {
            image: bundles.unitslibrarywindow.frames.window_background
        }
    }
};
