/**
 * Created by spepa on 26.09.2022
 */

Ruins.TIERS.halloween = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "crystal", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "drfreechest", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "crystal", stage: 3, amount: 1 },
            { code: "worker", stage: 4, amount: 1 },
            { code: "drshopchest", stage: 1, amount: 1 },
            { code: "bppointsstar", stage: 0, amount: 3 }
        ]
    }
};

MissionTree.PRIZES.halloween = [{
    amount: 5,
    units: [
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 1 },
        { code: "drgrowing", stage: 2 }
    ]
}];

Landmarks.WORLDS.halloween = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 9,
        targetExpedition: "main",
        donors: []
    }, {
        code: "landmark",
        stage: 18,
        targetExpedition: "main",
        donors: ["rpcustomermain1a", "rpcustomermain2a", "rpcustomermain3a"]
    }]
};

CustomerRecipes.halloween = cleverapps.clone(CustomerRecipes.universal, true);
CustomerRecipes.halloween.rpproducer0_0[0].cookingDuration = "10 minutes";
CustomerRecipes.halloween.rpproducer0_0[0].ingredients[0] = {
    code: "drfruit",
    stage: 4,
    amount: 1
};

CustomerRecipes.halloween.rpcustomerbridge1_0[0].requiredUnits[0].code = "rpcustomermain0a";
CustomerRecipes.halloween.rpcustomerbridge2_0[0].requiredUnits[0].code = "rpcustomermain1a";
CustomerRecipes.halloween.rpcustomerbridge3_0[0].requiredUnits[0].code = "rpcustomermain2a";

CustomerRecipes.halloween.rpproducer1_0[0].requiredUnits[0].code = "rpcustomermain0a";
CustomerRecipes.halloween.rpproducer2_0[0].requiredUnits[0].code = "rpcustomermain1a";
CustomerRecipes.halloween.rpproducer3_0[0].requiredUnits[0].code = "rpcustomermain2a";

CustomerRecipes.halloween.rpcustomerbridge0_0[0].ingredients[0].type = "drresource";
CustomerRecipes.halloween.rpcustomerbridge0_0[0].ingredients[0].amount = 1;