/**
 * Created by razial on 16.08.2024.
 */

Harvested.CONFIG.adventure2 = {
    capacity: 250,
    price: 250,
    exchangeMinimum: 50,

    resources: {
        advgrass: {
            icon: bundles.resource_icons.frames.resource_advgrass,
            smallIcon: bundles.resource_icons.frames.resource_advgrass_small
        },
        advleaves: {
            icon: bundles.resource_icons.frames.resource_advleaves,
            smallIcon: bundles.resource_icons.frames.resource_advleaves_small
        },
        advlog: {
            icon: bundles.resource_icons.frames.resource_advlog,
            smallIcon: bundles.resource_icons.frames.resource_advlog_small
        },
        advstick: {
            icon: bundles.resource_icons.frames.resource_advstick,
            smallIcon: bundles.resource_icons.frames.resource_advstick_small
        },
        advstone: {
            icon: bundles.resource_icons.frames.resource_advstone,
            smallIcon: bundles.resource_icons.frames.resource_advstone_small
        },
        advblueberry: {
            icon: bundles.resource_icons.frames.resource_advblueberry,
            smallIcon: bundles.resource_icons.frames.resource_advblueberry_small
        },
        advlychee: {
            icon: bundles.resource_icons.frames.resource_advlychee,
            smallIcon: bundles.resource_icons.frames.resource_advlychee_small
        },
        advbanana: {
            icon: bundles.resource_icons.frames.resource_advbanana,
            smallIcon: bundles.resource_icons.frames.resource_advbanana_small
        },
        advorange: {
            icon: bundles.resource_icons.frames.resource_advorange,
            smallIcon: bundles.resource_icons.frames.resource_advorange_small
        },
        advbalsam: {
            icon: bundles.resource_icons.frames.resource_advbalsam,
            smallIcon: bundles.resource_icons.frames.resource_advbalsam_small
        },
        advcloth: {
            icon: bundles.resource_icons.frames.resource_advcloth,
            smallIcon: bundles.resource_icons.frames.resource_advcloth_small
        },
        advballoon0: {
            icon: bundles.resource_icons.frames.resource_advballoon0,
            smallIcon: bundles.resource_icons.frames.resource_advballoon0_small
        },
        advballoon1: {
            icon: bundles.resource_icons.frames.resource_advballoon1,
            smallIcon: bundles.resource_icons.frames.resource_advballoon1_small
        },
        advballoon2: {
            icon: bundles.resource_icons.frames.resource_advballoon2,
            smallIcon: bundles.resource_icons.frames.resource_advballoon2_small
        }
    }
};

CustomerRecipes.adventure2 = {
    adv2customerbridge0_0: [{
        ingredients: [{
            ingredient: "advstick",
            amount: 2
        }, {
            ingredient: "advleaves",
            amount: 2
        }],
        order: [
            {
                code: "energy",
                stage: 1,
                amount: 1
            }
        ]
    }],

    adv2customerbridge1_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customerbridge1_1: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customermain0_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customermain1_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customermain2_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customermain5_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customermain6_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2customermain7_0: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2producer0_0: [{
        ingredients: [{
            ingredient: "advstone",
            amount: 1
        }, {
            ingredient: "advstick",
            amount: 1
        }, {
            ingredient: "advlog",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }],

    adv2producer0_1: [{
        ingredients: [{
            ingredient: "advblueberry",
            amount: 1
        }, {
            ingredient: "advgrass",
            amount: 1
        }],
        order: [{
            code: "energy",
            stage: 1,
            amount: 1
        }]
    }]
};
