/**
 * Created by r4zi4l on 25.01.2022
 */

var MergeAdviceFieldLogic = function (options) {
    options = options || {};

    MergeAdviceBaseLogic.call(this, options);

    this.action = options.action;
};

MergeAdviceFieldLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceFieldLogic.prototype.constructor = MergeAdviceFieldLogic;

MergeAdviceFieldLogic.prototype.getShowHintTimeout = function (move) {
    var steps = move && move.resources && move.resources.length || 0;
    return MergeAdvice.ONE_ITERATION_DURATION + 300 * steps;
};

MergeAdviceFieldLogic.prototype.findMove = function () {
    var used = {};
    var clusters = [];

    var units = this.listAvailableUnits().filter(function (unit) {
        return unit.findComponent(FieldComponent);
    });

    if (this.action) {
        units = units.filter(function (unit) {
            var info = unit.getActionInfo();
            return info && info.actions && info.actions.some(function (action) {
                return action.type === this.action.type;
            }, this);
        }, this);
    }

    units.forEach(function (unit) {
        var key = Unit.GetPositionKey(unit);

        if (!used[key]) {
            var selectedUnits = Map2d.currentMap.bfs(unit.x, unit.y, this.compareUnits.bind(this, unit)).map(function (cell) {
                return Map2d.currentMap.getUnit(cell.x, cell.y);
            });
            selectedUnits.forEach(function (selectedUnit) {
                used[Unit.GetPositionKey(selectedUnit)] = true;
            });
            clusters.push({
                priority: this.calcPriority(selectedUnits),
                units: selectedUnits
            });
        }
    }, this);

    clusters = clusters.filter(function (cluster) {
        return cluster.priority > 0;
    });

    if (clusters.length) {
        clusters.sort(function (a, b) {
            return b.priority - a.priority || b.length - a.length;
        });

        var selected = clusters[0].units;
        if (selected.length) {
            selected.sort(function (a, b) {
                return Number(InfoView.IsDisplayedFor(b)) - Number(InfoView.IsDisplayedFor(a)) || a.x - b.x || a.y - b.y;
            });

            var distance = function (a, b) {
                return Math.abs(a.x - b.x) + Math.abs(a.y - b.y);
            };

            for (var i = 0; i < selected.length - 1; ++i) {
                for (var j = i + 1; j < selected.length; ++j) {
                    if (distance(selected[i], selected[j]) <= 1) {
                        var tmp = selected[i + 1];
                        selected[i + 1] = selected[j];
                        selected[j] = tmp;
                        break;
                    }
                }
                if (j === selected.length) {
                    selected.length = i + 1;
                    break;
                }
            }

            return {
                cells: [selected[0]],
                unitView: Map2d.SPAWN,
                resources: selected
            };
        }
    }
};

MergeAdviceFieldLogic.prototype.calcPriority = function (units) {
    if (units.some(function (unit) {
        return InfoView.IsDisplayedFor(unit);
    })) {
        return 3;
    }

    var state = units[0].findComponent(FieldComponent).getState();
    if (state === FieldComponent.STATE_READY) {
        return 2;
    } if (state === FieldComponent.STATE_EMPTY) {
        return 1;
    }

    return 0;
};

MergeAdviceFieldLogic.prototype.compareUnits = function (target, x, y) {
    var unit = Map2d.currentMap.getUnit(x, y);

    if (!Unit.Equals(target, unit)) {
        return false;
    }

    target = target.findComponent(FieldComponent);
    unit = unit.findComponent(FieldComponent);
    return target.getState() === unit.getState();
};
