/**
 * Created by olga on 19.09.2024
 */

var FogSavers = function () {
    this.initialize();
};

FogSavers.prototype.initialize = function () {
    this.instances = {};

    CustomSyncers.SLOTS.forEach(function (slot) {
        this.instances[slot] = this.getInstance(slot);
    }, this);
};

FogSavers.prototype.getInstance = function (slot) {
    if (!this.instances[slot]) {
        this.instances[slot] = new FogSaver(slot);
    }
    return this.instances[slot];
};

FogSavers.prototype.reset = function () {
    CustomSyncers.SLOTS.forEach(function (slot) {
        cleverapps.dataLoader.save(DataLoader.TYPES.FOGS + slot, []);
        cleverapps.synchronizer.addUpdateTask("fogs" + slot);
    });
};