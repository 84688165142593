/**
 * Created by Anastasiya on 22.05.2024
 */

var UnitTradeIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.trade_icon_json
    });
};

UnitTradeIcon.prototype = Object.create(SideBarIcon.prototype);
UnitTradeIcon.prototype.constructor = UnitTradeIcon;

UnitTradeIcon.prototype.onPressed = function () {
    cleverapps.meta.display({
        focus: "TradeWindow",
        actions: [
            function (f) {
                new TradeWindow(this._needTutorial);
                delete this._needTutorial;
                cleverapps.meta.onceNoWindowsListener = f;
            }.bind(this)
        ]
    });
};

UnitTradeIcon.prototype.getForce = function () {
    return Forces.TRADE_WINDOW_FORCE;
};

UnitTradeIcon.prototype.resetState = function () {
    this._needTutorial = !cleverapps.forces.isShown(Forces.TRADE_WINDOW_FORCE.id);
    this.available = cleverapps.user.checkAvailable(cleverapps.Availables.TRADE);
};