/**
 * Created by andrey on 11.02.2021.
 */

var QuestsGroupIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.quests_icon_json,
        control: "QuestsGroupIcon"
    });

    this.activeInfoView = undefined;
    this.canShowInfoView = true;

    cc.eventManager.addListener(cc.EventListener.create({
        event: cc.EventListener.TOUCH_ONE_BY_ONE,
        onTouchBegan: this.handleClickOutside.bind(this)
    }), -1);

    if ("mouse" in cc.sys.capabilities) {
        cc.eventManager.addListener(cc.EventListener.create({
            event: cc.EventListener.MOUSE,
            onMouseScroll: this.handleClickOutside.bind(this)
        }), -1);
    }

    this.onScrollToItemListener = function () {};
    this.onGetIconViewListener = function () {};
    this.onAddIconViewListener = function () {};
    this.onRemoveIconViewListener = function () {};
};

QuestsGroupIcon.prototype = Object.create(SideBarIcon.prototype);
QuestsGroupIcon.prototype.constructor = QuestsGroupIcon;

QuestsGroupIcon.prototype.resetState = function () {
    this.available = Game.currentGame && Game.currentGame.quests && Game.currentGame.quests.config && !cleverapps.config.editorMode;
};

QuestsGroupIcon.prototype.onPressed = function (iconView) {
    if (!this.activeInfoView || this.activeInfoView.questIcon !== iconView) {
        this.showInfo(iconView, {});
    }
};

QuestsGroupIcon.prototype.allowInfoView = function () {
    this.canShowInfoView = true;
};

QuestsGroupIcon.prototype.denyInfoView = function () {
    this.canShowInfoView = false;
    this.hideInfo();
};

QuestsGroupIcon.prototype.hideInfo = function () {
    if (this.activeInfoView && !this.activeInfoView.isCompletion()) {
        this.activeInfoView.hide();
        delete this.activeInfoView;
    }
};

QuestsGroupIcon.prototype.showInfo = function (iconView, options) {
    this.hideInfo();

    if (!this.canShowInfoView) {
        return;
    }

    var questInfoView = new QuestInfoView(iconView, options);

    cleverapps.scenes.getRunningScene().movingNode.addChild(questInfoView);
    addCleaner(questInfoView, function () {
        if (this.activeInfoView === questInfoView) {
            delete this.activeInfoView;
        }
    }.bind(this));

    this.activeInfoView = questInfoView;
};

QuestsGroupIcon.prototype.getForce = function () {
};

QuestsGroupIcon.prototype.getView = function () {
    return QuestsGroupIconView;
};

QuestsGroupIcon.prototype.countOfSlots = function () {
    return 3;
};

QuestsGroupIcon.prototype.handleClickOutside = cleverapps.accumulate(0, function (touch) {
    if (this.activeInfoView && !this.activeInfoView.isCompletion()) {
        var isClickToInfoView = cc.rectContainsPoint(cc.rect(0, 0, this.activeInfoView.width, this.activeInfoView.height), this.activeInfoView.convertTouchToNodeSpace(touch));

        var icon = this.activeInfoView.questIcon;
        var isClickToIcon = cc.rectContainsPoint(cc.rect(0, 0, icon.width, icon.height), icon.convertTouchToNodeSpace(touch));

        if (!isClickToInfoView && !isClickToIcon) {
            this.activeInfoView.hide();
            delete this.activeInfoView;
        }
    }
});

QuestsGroupIcon.prototype.addQuestIcon = function (iconView) {
    cleverapps.audio.playSound(bundles.questsgroupicon.urls.quest_new_close_effect);

    this.onAddIconViewListener(iconView);
    this.showInfo(iconView, {
        duration: 1.5,
        delay: 0.8
    });
};

QuestsGroupIcon.prototype.tryFinishQuest = function (quest, callback) {
    var iconView = this.onGetIconViewListener(quest);

    if (!iconView) {
        callback();
        return;
    }

    new ActionPlayer([
        function (f) {
            this.onScrollToItemListener(iconView, f);
        }.bind(this),

        function (f) {
            this.canShowInfoView = true;
            this.showInfo(iconView, {
                callback: f
            });
        }.bind(this),

        function (f) {
            this.onRemoveIconViewListener(iconView, f);
            callback();
        }.bind(this)
    ]).play();

    return true;
};