/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.rapunzel_expedition = cleverapps.SkinManager.SKINS.rapunzel2_expedition = cleverapps.SkinManager.SKINS.rapunzel3_expedition = {
    bundle: "expedition_rapunzel",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        workerCutTime: function () {
            return 500;
        },

        workerSound: function () {
            return bundles.merge_rapunzel.urls.worker_sound;
        },

        workerMiningAnimationStyles: function () {
            return cleverapps.styles.rapunzel.miningAnimation;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_rapunzel.frames["small_icon_family_" + unit.code];
        },

        gameAudio: function () {
            return bundles.expedition_rapunzel.urls.rapunzel_music;
        },

        gameSceneBg: function () {
            return {
                movableAnimation: bundles.expedition_rapunzel.jsons.rapunzel_bg,
                parallax: {
                    hideHorizon: true
                }
            };
        },

        outOfBoundaryScale: function () {
            return 0;
        },

        noPoiFog: function (unit) {
            if (unit) {
                var code = unit.head ? unit.head.code : unit.code;
                return ["rpcustomerbridge0", "rpcustomerbridge1", "rpcustomerbridge2"].includes(code);
            }
        },

        fogBlocker: function () {
            return cleverapps.styles.rapunzel.fogBlockerLock;
        },

        worker_json: function () {
            return bundles.merge_rapunzel.jsons.worker_json;
        },

        whirlwind: bundles.fog_animations.jsons.rapunzel_whirlwind,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.rapunzel_expedition_buildpass_icon_json,

        toolbar_customers_icon_png: bundles.toolbar.frames.rapunzel_toolbar_customers_icon_png,

        fog_disappear_effect: function () {
            return bundles.fog_rapunzel.urls.open_effect;
        }
    })
};
