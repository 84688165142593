/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["repeatedQuests"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: 1,
    filter: function () {
        return cleverapps.environment.isMainScene() && Game.currentGame && Game.currentGame.quests.isRepeatedQuestsAvailable();
    },

    action: function () {
        Game.currentGame.quests.runRepeatedQuests();
    },

    interval: cleverapps.config.debugMode ? "10 seconds" : "1 hour"
};

Placements.ENTRIES["runSpecialEnergyOffer"] = {
    type: Placements.FREE_FOCUS,

    filter: function () {
        return cleverapps.environment.isMainScene()
            && Game.currentGame.specialEnergyOffer.showPlacement;
    },

    action: function () {
        Game.currentGame.specialEnergyOffer.showPlacement = false;

        Game.currentGame.specialEnergyOffer.displayWindow();
    }
};

Placements.ENTRIES["growthFund"] = {
    type: Placements.FREE_FOCUS_MAIN,
    priority: -2,

    filter: function () {
        return cleverapps.growthFund && cleverapps.growthFund.wantsAttention && cleverapps.growthFund.isActive();
    },

    action: function () {
        cleverapps.meta.display({
            focus: "GrowthFundNudge",
            action: function (f) {
                new GrowthFundWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            }
        });
    }
};

Placements.ENTRIES["showBonusWorkerWindow"] = {
    type: Placements.FREE_FOCUS,

    filter: function () {
        return Map2d.currentMap && Map2d.currentMap && Map2d.currentMap.workers.wantsToShowBonusWorkerWindow;
    },

    action: function () {
        cleverapps.meta.display({
            focus: "BonusWorkerWindow",
            actions: [
                function (f) {
                    new BonusWorkerWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            ]
        });
    }
};

Placements.ENTRIES["seasonWindow"] = {
    type: Placements.FREE_FOCUS_MAIN,

    filter: function () {
        return Game.currentGame && Game.currentGame.season && Game.currentGame.season.hasFresh();
    },

    action: function () {
        Game.currentGame.season.processFresh();
    }
};

Placements.ENTRIES["travelBook"] = {
    type: Placements.FREE_FOCUS,
    priority: 1,

    filter: function () {
        return cleverapps.environment.isMainScene();
    },

    action: function () {
        cleverapps.travelBook.updatePages();
        cleverapps.toolbar.resetByType(ToolbarItem.TRAVEL_BOOK);
    }
};