/**
 * Created by Denis Kuzin on 15 december 2021
 */

cleverapps.SkinManager.SKINS.xmas_expedition = {
    name: "xmas",

    bundle: "expedition_xmas",

    slots: {
        workerSkin: function () {
            return cleverapps.config.name === "mergecraft" ? "xm" : undefined;
        },

        windowDecors: function (options) {
            return cleverapps.SkinManager.SKINS.xmas.slots.windowDecors(options);
        },

        ruinsRewardsDelay: function () {
            return 0;
        },

        ruinsWindow: function () {
            return {
                bundle: "ruinswindow_xmas",
                dictionaryKey: "RuinsWindow.xmax.",
                person: {
                    role: "worker",
                    skin: cleverapps.config.name === "mergecraft" ? "xmas" : undefined
                }
            };
        },

        missionTableRowIcon: function (options) {
            if (Mission.GetChildType(options.type) === Mission.TYPE_EXPEDITION_FEAST) {
                return bundles.tablerow_icons.frames.feast_snowflake;
            }
        },

        gameAudio: function () {
            return bundles.expedition_xmas.urls.xmas_music;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons.frames["small_icon_family_" + unit.code];
        },

        unitOnCell: function () {
            return cleverapps.styles.UnitOnCellExpedition;
        },

        gameSceneBg: function () {
            return {
                surfaceAnimation: bundles.expedition_xmas.jsons.xmas_bg_animation_top
            };
        },

        fogBlocker: function () {
            return cleverapps.styles.xmas.fogBlockerLock;
        },

        whirlwind: bundles.fog_animations.jsons.xmas_whirlwind,

        merge_wand_png: bundles.menubar.frames.xmas_wand_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.xmas_expedition_buildpass_icon_json,
        expedition_feast_icon_json: bundles.sidebar.jsons.xmas_expedition_feast_icon_json,
        toolbar_customers_icon_png: bundles.toolbar.frames.xmas_toolbar_customers_icon_png,

        expedition_feast_icon: bundles.reward_icons.frames.xmas_expedition_feast_icon,
        reward_wand_png: bundles.reward_icons.frames.xmas_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.xmas_reward_wand_small_png,

        expeditionPassGuideBundle: function () {
            return bundles.xmas_expeditionguidewindow;
        },

        expeditionFeastBundle: function () {
            return bundles.expedition_feast_xmas;
        },

        landmarkGuideOptions: function () {
            return {
                name: "xmas_product_guidewindow",
                bundle: bundles.xmas_product_guidewindow,
                title: "LandmarkGuideWindow.title"
            };
        },

        fog_disappear_effect: function () {
            return bundles.fog_xmas.urls.open_effect;
        }
    }
};
