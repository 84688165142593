/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.universal = {
    rpcustomerbridge0_0: {
        trigger: { fog: "fog1" },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpcustomerbridge0", stage: 0 }
        }
    },
    rpcustomerbridge0_1: {
        trigger: { unit: { code: "rpcustomerbridge0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge0", stage: 1 }
        },
        dynamic: {
            getIcon: function () {
                var skin = cleverapps.skins.getSlot("skinName");
                var code = "rpcustomerbridge0";

                var icon = bundles.quests.frames[skin + "_" + code] || bundles.quests.frames[code];

                return new cc.Sprite(icon);
            }
        }
    },
    rpcustomermain0a_1: {
        trigger: { unit: { code: "rpcustomerbridge0", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain0a", stage: 1 }
        }
    },
    rpproducer0: {
        trigger: { unit: { code: "rpcustomermain0a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer0", stage: 0 }
        }
    },
    rpproduct0: {
        trigger: { unit: { code: "rpproducer0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct0", stage: 0 }
        }
    },
    rpcustomermain0b_1: {
        trigger: { unit: { code: "rpcustomermain0a", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain0b", stage: 1 }
        }
    },
    rpcustomerbridge1_1: {
        trigger: { unit: { code: "rpcustomermain0b", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge1", stage: 1 }
        }
    },
    rpcustomermain1a_1: {
        trigger: { unit: { code: "rpcustomerbridge1", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain1a", stage: 1 }
        }
    },
    rpproducer1: {
        trigger: { unit: { code: "rpcustomermain1a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer1", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var skin = cleverapps.skins.getSlot("skinName");
                var code = "rpproducer1";

                var icon = bundles.quests.frames[skin + "_" + code] || bundles.quests.frames[code];

                return new cc.Sprite(icon);
            }
        }
    },
    rpproduct1: {
        trigger: { unit: { code: "rpproducer1", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct1", stage: 0 }
        }
    },
    rpcustomermain1b_1: {
        trigger: { unit: { code: "rpcustomermain1a", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain1b", stage: 1 }
        }
    },
    rpcustomermain1c_1: {
        trigger: { unit: { code: "rpcustomermain1b", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain1c", stage: 1 }
        }
    },
    rpcustomerbridge2_1: {
        trigger: { unit: { code: "rpcustomermain1c", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge2", stage: 1 }
        }
    },
    rpcustomermain2a_1: {
        trigger: { unit: { code: "rpcustomerbridge2", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain2a", stage: 1 }
        }
    },
    rpproducer2: {
        trigger: { unit: { code: "rpcustomermain2a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer2", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var skin = cleverapps.skins.getSlot("skinName");
                var code = "rpproducer2";

                var icon = bundles.quests.frames[skin + "_" + code] || bundles.quests.frames[code];

                return new cc.Sprite(icon);
            }
        }
    },
    rpproduct2: {
        trigger: { unit: { code: "rpproducer2", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct2", stage: 0 }
        }
    },

    rpcustomermain2b_1: {
        trigger: { unit: { code: "rpcustomermain2a", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain2b", stage: 1 }
        }
    },
    rpcustomermain2c_1: {
        trigger: { unit: { code: "rpcustomermain2b", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain2c", stage: 1 }
        }
    },
    rpcustomermain2d_1: {
        trigger: { unit: { code: "rpcustomermain2c", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain2d", stage: 1 }
        }
    },
    rpcustomerbridge3_1: {
        trigger: { unit: { code: "rpcustomermain2d", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomerbridge3", stage: 1 }
        }
    },
    rpcustomermain3a_1: {
        trigger: { unit: { code: "rpcustomerbridge3", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain3a", stage: 1 }
        }
    },
    rpproducer3: {
        trigger: { unit: { code: "rpcustomermain3a", stage: 0 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "rpproducer3", stage: 0 }
        },
        dynamic: {
            getIcon: function () {
                var skin = cleverapps.skins.getSlot("skinName");
                var code = "rpproducer3";

                var icon = bundles.quests.frames[skin + "_" + code] || bundles.quests.frames[code];

                return new cc.Sprite(icon);
            }
        }
    },
    rpproduct3: {
        trigger: { unit: { code: "rpproducer3", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpproduct3", stage: 0 }
        }
    },
    rpcustomermain3b_1: {
        trigger: { unit: { code: "rpcustomermain3a", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain3b", stage: 1 }
        }
    },
    rpcustomermain3c_1: {
        trigger: { unit: { code: "rpcustomermain3b", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain3c", stage: 1 }
        }
    },
    rpcustomermain3d_1: {
        trigger: { unit: { code: "rpcustomermain3c", stage: 1 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "rpcustomermain3d", stage: 1 }
        }
    }
};
