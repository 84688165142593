/**
 * Created by r4zi4l on 27.01.2022
 */

var ResourceIcon = cc.Node.extend({
    ctor: function (action, resourceView) {
        this._super();
        this.action = action;
        this.resourceView = resourceView;
        this.dragging = false;

        if (!action.locked) {
            this.adviceTarget = Map2d.SPAWN;
        }

        var styles = cleverapps.styles.ResourceIcon;

        var icon = this.createIcon(action);

        this.setContentSize2(styles.width, styles.height);
        this.setAnchorPoint(0.5, 0.5);
        this.setCascadeOpacityEnabled(true);
        this.setCascadeColorEnabled(true);

        icon.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(icon);

        if (action.locked) {
            this.setOpacity(120);
            this.setColor(new cc.Color(0, 150, 255, 255));

            cleverapps.UI.onClick(this, function () {}, {
                interactiveScale: false,
                onLongClick: this.createListener(this.onMouseOver.bind(this))
            });
        } else {
            cleverapps.UI.onClick(this, this.createListener(this.onClick.bind(this, this.resourceView.unit)), {
                onLongClick: this.createListener(this.onMouseOver.bind(this))
            });

            cleverapps.UI.onDrag(this, {
                followPointer: true,
                onDragStart: this.createListener(this.onDragStart.bind(this)),
                onDragMove: this.createListener(this.onDragMove.bind(this)),
                onDragEnd: this.createListener(this.onDragEnd.bind(this))
            });

            cleverapps.eventBus.on("changeFocus", function () {
                if (cleverapps.meta.focus !== "dragging") {
                    this.onDragEnd();
                }
            }.bind(this), this);
        }

        cleverapps.UI.applyHover(this);
    },

    createIcon: function () {
        var styles = cleverapps.styles.ResourceIcon;
        var icon;

        if (this.action.type === ResourceView.ACTION_HARVEST) {
            icon = new cc.Sprite(bundles.merge.frames.harvest_field);
        } else {
            icon = UnitView.getUnitImage(this.action.unit, {
                alignAnchorX: true,
                preferStatic: true
            });
        }

        cleverapps.UI.fitToBox(icon, {
            width: styles.width,
            height: styles.height,
            maxScale: 1.8
        });

        return icon;
    },

    createAdviceIcon: function () {
        var icon = this.createIcon();
        icon.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(icon);
        return icon;
    },

    onClick: function (unit) {
        for (var i = 0; i < unit.components.length; ++i) {
            var component = unit.components[i];
            if (component.canAcceptAction && component.canAcceptAction(this.action)) {
                component.acceptAction(this.action);
                break;
            }
        }
    },

    onDragStart: function () {
        if (this.dragging || Map2d.currentMap.dragging) {
            return false;
        }

        this.baseParent = this.getParent();
        this.basePosition = this.getPosition();
        this.replaceParentSamePlace(Map2d.currentMap.getView().animations, {
            keepScale: true
        });
        this.runAction(new cc.ScaleTo(0.2, 1.3));
        Map2d.currentMap.setDragging(this.action);
        cleverapps.userStatus.reportUserAction();
        this.dragging = true;
        return true;
    },

    onDragMove: function (touch) {
        if (this.dragging) {
            var unit = Map2d.currentMap.getView().findTouchUnit(touch);
            if (unit) {
                this.onClick(unit);
            }
        }
    },

    onDragEnd: function () {
        if (this.dragging) {
            if (this.baseParent.isRunning()) {
                this.replaceParent(this.baseParent);
                this.setPosition(this.basePosition);
                this.setScale(1);
            } else {
                this.removeFromParent();
            }

            this.dragging = false;

            if (Map2d.currentMap.dragging === this.action) {
                Map2d.currentMap.setDragging(undefined);
            }
        }

        this.baseParent = undefined;
        this.basePosition = undefined;
    },

    onMouseOver: function () {
        if (!this.action.locked) {
            this.applyInteractiveScale();
        }
        this.resourceView.showDetails(this, this.action);
    },

    onMouseOut: function () {
        if (!this.action.locked) {
            this.applyInteractiveScale();
        }
        this.resourceView.hideDetails();
    },

    onTouchEnded: function () {
        this.onMouseOut();
    }
});

cleverapps.styles.ResourceIcon = {
    width: 130,
    height: 110
};
